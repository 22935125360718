import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Footer from '../components/FooterEvent'


import HeaderEvent from '../components/HeaderEvent';
import fusion from '../assets/images/carriers/logo_fusion.gif';
import aerohive from '../assets/images/carriers/aerohive.png';

import pic04 from '../assets/images/pic04.jpg'
import Register from '../components/Register.jsx';

class Generic extends React.Component {
  render() {
    const eventSponsors = [fusion, aerohive];
    return (
      <span>
      <div>
        <Helmet>
          <title>Success!</title> 
        </Helmet>
        <HeaderEvent isLead sponsors={eventSponsors}/>
        <div id="main" className="register__main">
          <section id="content" className="main">
            <div style={{textAlign: 'center'}} >
              <h1>Successfully Registered! </h1><br /> 
              <a href="/">
                Return
              </a>
            </div>
          </section>
        </div>

      </div>
      <Footer />
      </span>
    )
  }
}

export default Generic

export const pageQuery = graphql`
  query RegisterSuccessQuery{
    site {
      siteMetadata {
        title
      }
    }
  }
`
